import React, { useContext, useEffect, useMemo, useState } from 'react';
import { usePageContext } from './usePageContext';

type TMenuContext = {
  open: boolean;
  setOpen: (open: boolean) => void;
  activeLink: string;
  setActiveLink: (link: string) => void;
};

const MenuContext = React.createContext<TMenuContext | undefined>(undefined);

export const MenuContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { originalPath, paths } = usePageContext();
  const [open, setOpen] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<string>('');

  useEffect(() => {
    setOpen(false);
  }, [originalPath]);

  useEffect(() => {
    if (paths?.length) {
      setActiveLink(`${paths[0].url}/`);
    } else if (originalPath) {
      setActiveLink(originalPath);
    }
  }, [paths, originalPath]);

  const value = useMemo(
    () => ({
      open,
      setOpen,
      activeLink,
      setActiveLink,
    }),
    [open, setOpen, activeLink, setActiveLink]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenuContext = () => {
  const menuContext = useContext(MenuContext);

  if (!menuContext) throw new Error('No MenuContext.Provider found when calling usePageContext.');
  return menuContext;
};

import React from 'react';
import { langs } from '../../../../components/2.0version/langSelector/LangSelector';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import * as styles from './langSwitch.module.scss';

export const LangSwitch = () => {
  const { lang, setLang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      {langs.map((el) => (
        <button
          type="button"
          onClick={() => setLang(el.code)}
          className={`${lang === el?.code && styles.active}`}
          key={`lang-switch-${el.code}`}
        >
          {el.label?.[lang]}
        </button>
      ))}
    </div>
  );
};

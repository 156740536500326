import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SmartLink } from '../../smartLink/SmartLink';
import { ButtonsFooterQuery } from '../../../../../graphql-types';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import { Button } from '../../button/Button';
import * as styles from './buttonsSection.module.scss';

export const ButtonsSection = () => {
  const { lang } = usePageContext();
  const { sanityFooterv2 } = useStaticQuery<ButtonsFooterQuery>(QUERY);
  const buttons = sanityFooterv2?.buttons;
  return (
    <div className={styles.mainWrapper}>
      {buttons?.map((button) => (
        <Button key={button?._key} name={button?._rawTitle[lang]}>
          <SmartLink link={button}>{button?._rawTitle[lang]}</SmartLink>
        </Button>
      ))}
    </div>
  );
};

const QUERY = graphql`
  query buttonsFooter {
    sanityFooterv2 {
      buttons {
        ...linkFragment
      }
    }
  }
`;

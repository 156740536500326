import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { PartnersFooterQuery } from '../../../../../graphql-types';
import * as styles from './partnersSection.module.scss';

const RenderImage = ({ alt, image }: { alt: string; image: ImageDataLike }) => {
  const gastbyImage = getImage(image);
  if (!gastbyImage) return null;
  return <GatsbyImage image={gastbyImage} alt={alt} />;
};

export const PartnersSection = () => {
  const { sanityFooterv2 } = useStaticQuery<PartnersFooterQuery>(QUERY);
  const partners = sanityFooterv2?.partners;
  return (
    <div className={styles.mainWrapper}>
      {partners?.map((partner) => {
        if (partner)
          return (
            <RenderImage
              key={partner._key}
              alt={partner?.alt ?? 'alt defaut'}
              image={partner?.image?.asset?.gatsbyImageData}
            />
          );
        return null;
      })}
    </div>
  );
};

const QUERY = graphql`
  query partnersFooter {
    sanityFooterv2 {
      partners {
        alt
        _key
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

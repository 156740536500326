import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import MessengerCustomerChat from 'react-messenger-customer-chat';
// import Cookies from 'universal-cookie';
import { MenuDesktop } from '../modules/menu/MenuDesktop';
import { usePageContext } from '../hooks/contexts/usePageContext';
import { Menu } from '../modules/menu/Menu';
import { DesktopTopBar } from './header/DesktopTopBar';
import { Header } from './header/Header';
import { HeaderDesktop } from './header/HeaderDesktop';
import { Footerv2 } from '../components/2.0version/footer/Footerv2';
import * as colors from '../styles/colors.module.scss';
import * as styles from './layout.module.scss';
import { MessengerQuery } from '../../graphql-types';
import { ScrollButton } from '../components/2.0version/scrollButton/ScrollButton';
import { FloatMenuDesktop } from './header/FloatMenuDesktop';
import { SEO } from '../components/2.0version/seo/Seo';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: ILayoutProps) => {
  const { sanityMessengerSettings } = useStaticQuery<MessengerQuery>(QUERY);
  const { menuClear, menuRed } = usePageContext();
  const { ref: refObserver, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const cookies = new Cookies();

  const [scroll, setScroll] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };
  useEffect(() => {
    if (window && !scroll) {
      window.addEventListener('scroll', () => {
        setTimeout(() => {
          setScroll(true);
        }, 1000);
      });
      window.addEventListener('scroll', () => handleScroll());
    }

    return () => {
      if (window && !scroll) {
        window.removeEventListener('scroll', () => {});
        window.removeEventListener('scroll', () => handleScroll());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     if (cookies.get('gatsby-gdpr-google-tagmanager') === true) {
  //       window.gtag('config', 'GA_MEASUREMENT_ID', {
  //         linker: {
  //           domains: ['resa-tours.ingenie.fr'],
  //         },
  //       });
  //     }
  //   }
  // }, [cookies]);

  /** see _mixins */
  useEffect(() => {
    const scrollbarWidth =
      typeof window !== `undefined` ? window.innerWidth - document.body.clientWidth : 0;
    document.body.style.setProperty('--viewportWidth', `calc(100vw - ${scrollbarWidth}px)`);
  }, []);

  return (
    <div className={styles.wrapper}>
      <SEO />
      <DesktopTopBar />
      <Header />
      <HeaderDesktop refObserver={refObserver} />
      <FloatMenuDesktop hidden={inView} />
      <Menu />
      <MenuDesktop />
      {!(menuClear || menuRed) ? <div className={styles.menuBg} /> : ''}
      {menuRed ? <div className={styles.menuBgRed} /> : ''}
      {children}
      <Footerv2 />
      {sanityMessengerSettings?.appId && sanityMessengerSettings.pageId && scroll && (
        <MessengerCustomerChat
          pageId={sanityMessengerSettings.pageId}
          appId={sanityMessengerSettings.appId}
          themeColor={colors.redColor}
        />
      )}
      <ScrollButton visible={scrollTop} />
    </div>
  );
};

const QUERY = graphql`
  query messenger {
    sanityMessengerSettings {
      appId
      pageId
    }
  }
`;

import React, { useState } from 'react';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import * as styles from './floatMenuDesktop.module.scss';
import { useMenuContext } from '../../hooks/contexts/useMenuContext';
import { LogoSite } from '../../components/2.0version/logoSite/LogoSite';
import { SmartLink } from '../../components/2.0version/smartLink/SmartLink';
import { LocaleLink } from '../../components/2.0version/localeLink/LocaleLink';
import AccountIcon from '../../../static/svg/person.svg';
import SearchIcon from '../../../static/svg/search.svg';
import { FavoriteIcon } from '../../components/2.0version/favoriteIcon/FavoriteIcon';
import { WidgetCart } from '../../modules/ingenie/WidgetCart';
import { INGENIE_ACCOUNT_URL, URLS } from '../../../constants';
import { usePageContext } from '../../hooks/contexts/usePageContext';

interface IFloatMenuDesktop {
  hidden: boolean;
}

export const FloatMenuDesktop = ({ hidden }: IFloatMenuDesktop) => {
  const { lang, searchBarDisable } = usePageContext();
  const { sanityMenuv2, sanityTranslations } = useStaticQuery(QUERY);
  const [search, setSearch] = useState<string>('');

  const { open, setOpen, activeLink } = useMenuContext();

  return (
    <div className={styles.wrapperBg} data-hidden={hidden}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.menu}>
            <button
              aria-label="menu"
              type="button"
              className={styles.menuIcon}
              onClick={() => setOpen(!open)}
            >
              <FontAwesomeIcon icon={faBars} size="1x" />
            </button>
            <div>
              <LogoSite dark withoutText />
            </div>
            <nav>
              <ul>
                {sanityMenuv2?.desktopNavbarLinks?.map((el) => (
                  <li
                    key={`desktop-nav-${el?._key}`}
                    className={`${activeLink === `/${el?.staticPage}/` ? styles.underlined : ''}`}
                  >
                    <SmartLink link={el}>
                      <button type="button">
                        <span>{el?._rawTitle?.[lang]}</span>
                      </button>
                    </SmartLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className={styles.cta}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                navigate(`${URLS.recherche.url}?q=${search}`);
              }}
              className={styles.searchForm}
            >
              {!searchBarDisable && (
                <div>
                  <SearchIcon />
                  <input
                    placeholder={sanityTranslations?._rawSearchLabel?.[lang]}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    enterKeyHint={'search'}
                  />
                </div>
              )}
            </form>
            <LocaleLink
              to={URLS.recherche.url}
              className={styles.searchOnTablet}
              ariaLabel="search"
            >
              <SearchIcon />
            </LocaleLink>
            <LocaleLink to={INGENIE_ACCOUNT_URL[lang]} ariaLabel="account">
              <AccountIcon />
            </LocaleLink>
            <LocaleLink to={URLS.favorites.url} ariaLabel="favorites">
              <FavoriteIcon />
            </LocaleLink>
            <WidgetCart widgetContainerId="floatMenu" />
          </div>
        </div>
      </div>
    </div>
  );
};

const QUERY = graphql`
  query NavbarFloatDesktop {
    sanityMenuv2 {
      desktopNavbarLinks {
        ...linkFragment
      }
    }
    sanityTranslations {
      _rawSearchLabel
    }
  }
`;

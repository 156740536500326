import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { LogoSite } from '../../components/2.0version/logoSite/LogoSite';
import SearchIcon from '../../../static/svg/search.svg';
import AccountIcon from '../../../static/svg/person.svg';
import { LocaleLink } from '../../components/2.0version/localeLink/LocaleLink';
import { NavbarDesktopQuery } from '../../../graphql-types';
import { SmartLink } from '../../components/2.0version/smartLink/SmartLink';
import { usePageContext } from '../../hooks/contexts/usePageContext';
import { useMenuContext } from '../../hooks/contexts/useMenuContext';
import { INGENIE_ACCOUNT_URL, URLS } from '../../../constants';
import { WidgetCart } from '../../modules/ingenie/WidgetCart';
import * as styles from './headerDesktop.module.scss';
import { FavoriteIcon } from '../../components/2.0version/favoriteIcon/FavoriteIcon';

interface IHeaderDesktopProps {
  refObserver: (node?: Element | null | undefined) => void;
}

export const HeaderDesktop = ({ refObserver }: IHeaderDesktopProps) => {
  const { sanityMenuv2, sanityTranslations } = useStaticQuery<NavbarDesktopQuery>(QUERY);
  const { lang, menuClear, menuRed, searchBarDisable } = usePageContext();
  const { open, setOpen, activeLink } = useMenuContext();
  const [search, setSearch] = useState<string>('');

  return (
    <div
      ref={refObserver}
      className={`${styles.wrapper} ${menuClear || menuRed ? styles.clear : ''}`}
    >
      <div>
        <div>
          <button
            aria-label="menu"
            type="button"
            className={styles.menuIcon}
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon icon={faBars} size="1x" />
          </button>
          <LogoSite dark={!(menuClear || menuRed)} />
        </div>
        <div className={styles.searchContainer}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              navigate(`${URLS.recherche.url}?q=${search}`);
            }}
            className={styles.searchForm}
          >
            {!searchBarDisable && (
              <div>
                <SearchIcon className={styles.icon} />
                <input
                  placeholder={sanityTranslations?._rawSearchLabel?.[lang]}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  enterKeyHint="search"
                />
              </div>
            )}
          </form>
        </div>
      </div>
      <nav>
        <ul>
          {sanityMenuv2?.desktopNavbarLinks?.map((el) => (
            <li
              key={`desktop-nav-${el?._key}`}
              className={`${activeLink === `/${el?.staticPage}/` ? styles.underlined : ''}`}
            >
              <SmartLink link={el}>
                <button type="button">
                  <span>{el?._rawTitle?.[lang]}</span>
                </button>
              </SmartLink>
            </li>
          ))}
        </ul>
        <div className={styles.iconContainer}>
          <LocaleLink to={INGENIE_ACCOUNT_URL[lang]} ariaLabel="account">
            <AccountIcon />
          </LocaleLink>
          <LocaleLink to={URLS.favorites.url} ariaLabel="favorites">
            <FavoriteIcon />
          </LocaleLink>
          <WidgetCart widgetContainerId="frameIngenieCartContainerHeaderDesktop" />
        </div>
      </nav>
    </div>
  );
};

const QUERY = graphql`
  query NavbarDesktop {
    sanityMenuv2 {
      desktopNavbarLinks {
        ...linkFragment
      }
    }
    sanityTranslations {
      _rawSearchLabel
    }
  }
`;

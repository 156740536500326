import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as styles from './menuLinkContent.module.scss';

interface IMenuLinkContentProps {
  title?: string | null;
  icon?: IconProp;
  prefix?: 'far' | 'fal';
}

export const MenuLinkContent = ({ prefix = 'fal', title, icon }: IMenuLinkContentProps) => (
  <div className={`${styles.wrapper} ${icon ? styles.white : ''}`}>
    <div className={styles.leftSide}>
      {icon && <FontAwesomeIcon icon={[prefix, icon as IconName]} />}
      <p>{title}</p>
    </div>
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import * as styles from './accordion.module.scss';

interface IAccordionProps {
  id: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const toggleAccordion = (accordion: HTMLElement) => {
  accordion.classList.toggle(styles.active);
  const panel = accordion.nextElementSibling as HTMLElement;
  if (panel) {
    if (panel.style.maxHeight === '0px' || !panel.style.maxHeight) {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    } else {
      panel.style.maxHeight = `0px`;
    }
  }
};

export const Accordion = ({ id, title, children }: IAccordionProps) => {
  useEffect(() => {
    if (document && window) {
      const accordion = document.getElementById(id);

      if (accordion) {
        accordion.addEventListener('click', () => toggleAccordion(accordion));
        return () => accordion.removeEventListener('click', () => {});
      }
    }
    return () => {};
  }, [id]);

  return (
    <div>
      <button id={id} type="button" className={styles.accordion}>
        {title}
        <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
      </button>
      <div className={styles.panel}>{children}</div>
    </div>
  );
};

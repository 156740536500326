import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { NavFooterQuery } from '../../../../../graphql-types';
import { Accordion } from '../../accordion/Accordion';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import { SmartLink } from '../../smartLink/SmartLink';
import * as styles from './navSection.module.scss';

const LinksList = ({ links }: any) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.links}>
      {links.map((link) => (
        <SmartLink key={link._key} link={link}>
          {link._rawTitle[lang]}
        </SmartLink>
      ))}
    </div>
  );
};

export const NavSectionMobile = () => {
  const { lang } = usePageContext();
  const { sanityFooterv2 } = useStaticQuery<NavFooterQuery>(QUERY);
  const navSections = sanityFooterv2?.nav;
  return (
    <div className={styles.mainWrapperMobile}>
      {navSections?.map((section) => {
        if (section?._rawTitle && section.links) {
          return (
            <Accordion key={section._key} id={`${section._key}`} title={section._rawTitle[lang]}>
              <LinksList links={section.links} />
            </Accordion>
          );
        }
        return null;
      })}
    </div>
  );
};

const QUERY = graphql`
  query navFooter {
    sanityFooterv2 {
      nav {
        links {
          ...linkFragment
        }
        _rawTitle
        _key
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { Script } from 'gatsby';
import CartIcon from '../../../static/svg/shoppingCart.svg';

import { usePageContext } from '../../hooks/contexts/usePageContext';
import * as styles from './widgetCart.module.scss';

// const widgetContainerId = 'frameIngenieCartContainer';

// <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
// <script src="https://static.ingenie.fr/js/widgets/min/widget-resa.min.js"></script>
export const WidgetCart = ({ widgetContainerId = 'frameIngenieCartContainer' }) => {
  const { lang } = usePageContext();
  const [jqueryLoaded, setJqueryLoaded] = useState<boolean>(false);
  const [widgetLoaded, setWidgetLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && widgetLoaded && jqueryLoaded) {
      const params: any = {};
      params.url =
        lang === 'en'
          ? 'https://booking.tours-tourisme.fr/'
          : 'https://reservation.tours-tourisme.fr'; // (dynamique)
      params.cid = '1';
      params.div = widgetContainerId;
      window.widgetResaIngenie?.getPanierEnCours(params);
    }
  }, [widgetContainerId, lang, jqueryLoaded, widgetLoaded]);

  return (
    <div className={styles.mainWrapper}>
      <Script
        onLoad={() => setJqueryLoaded(true)}
        src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"
        type="application/javascript"
      />
      <Script
        onLoad={() => setWidgetLoaded(true)}
        src="https://static.ingenie.fr/js/widgets/min/widget-resa.min.js"
        type="text/javascript"
      />
      <CartIcon />
      <div id={widgetContainerId} />
    </div>
  );
};

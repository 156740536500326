import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { NavFooterQuery } from '../../../../../graphql-types';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import { SmartLink } from '../../smartLink/SmartLink';
import * as styles from './navSection.module.scss';

const LinksList = ({ links }: any) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.links}>
      {links.map((link) => (
        <SmartLink key={link._key} link={link}>
          {link._rawTitle[lang]}
        </SmartLink>
      ))}
    </div>
  );
};

export const NavSection = () => {
  const { lang } = usePageContext();
  const { sanityFooterv2 } = useStaticQuery<NavFooterQuery>(QUERY);
  const navSections = sanityFooterv2?.nav;
  return (
    <div className={styles.mainWrapper}>
      {navSections?.map((section) => {
        if (section?._rawTitle && section.links)
          return (
            <div key={section._key}>
              <div className={styles.title}>{section?._rawTitle[lang]}</div>
              <LinksList links={section.links} />
            </div>
          );
        return null;
      })}
    </div>
  );
};

const QUERY = graphql`
  query navFooter {
    sanityFooterv2 {
      nav {
        links {
          ...linkFragment
        }
        _rawTitle
        _key
      }
    }
  }
`;

import React, { useCallback, useContext, useMemo, createContext } from 'react';
import { ListingOtQuery } from '../../../graphql-types';
import useLocalStorage from '../useLocalStorage';

type TWishlistContext = {
  modifyWishlist: (selectedOt: TypeOt) => void;
  wishlist: TypeOt[];
  emptyWishlist: () => void;
};

type TypeOt = ListingOtQuery['allIngenieOt']['nodes'][0];

const WishlistContext = createContext<TWishlistContext | undefined>(undefined);

export const WishlistContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [wishlist, setWishlist] = useLocalStorage<TypeOt[]>('wishlist', []);

  const modifyWishlist = useCallback(
    (selectedOt: TypeOt) => {
      const tmpWish = [...wishlist];
      const otIndex = wishlist.findIndex((ot: TypeOt) => ot?.id === selectedOt.id);
      if (otIndex > -1) {
        tmpWish.splice(otIndex, 1);
        setWishlist(tmpWish);
      } else {
        setWishlist([...wishlist, selectedOt]);
      }
    },
    [wishlist, setWishlist]
  );

  const emptyWishlist = useCallback(() => setWishlist([]), [setWishlist]);

  const value = useMemo(
    () => ({
      modifyWishlist,
      wishlist,
      emptyWishlist,
    }),
    [emptyWishlist, modifyWishlist, wishlist]
  );

  return <WishlistContext.Provider value={value}>{children}</WishlistContext.Provider>;
};

export const useWishlistContext = () => {
  const wishlistContext = useContext(WishlistContext);

  if (!wishlistContext)
    throw new Error('No WishlistContext.Provider found when calling useWishlistContext.');
  return wishlistContext;
};

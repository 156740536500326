import React from 'react';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import { langs } from '../../langSelector/LangSelector';
import * as styles from './langsSection.module.scss';

export const LangsSection = () => {
  const { lang, setLang } = usePageContext();

  const activeLang = langs.find((el) => el.code === lang);
  const extraLangs = langs.filter((el) => el.code !== lang);
  return (
    <div className={styles.mainWrapper}>
      {langs.map((lg) => (
        <button
          type="button"
          onClick={() => setLang(lg.code)}
          key={`lang-select-footer-${lg.code}`}
          className={`${styles.button} ${activeLang?.code === lg.code ? styles.active : ''}`}
        >
          {lg.label[lang]?.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

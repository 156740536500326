import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { BottomLinksFooterQuery } from '../../../../../graphql-types';
import { SmartLink } from '../../smartLink/SmartLink';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import * as styles from './bottomLinks.module.scss';

export const BottomLinks = () => {
  const { lang } = usePageContext();
  const { sanityFooterv2 } = useStaticQuery<BottomLinksFooterQuery>(QUERY);
  const bottomLinks = sanityFooterv2?.bottomLinks;
  return (
    <div className={styles.mainWrapper}>
      {bottomLinks?.map((link) => (
        <SmartLink key={link?._key} link={link}>
          {link?._rawTitle[lang]?.toUpperCase()}
        </SmartLink>
      ))}
    </div>
  );
};

const QUERY = graphql`
  query bottomLinksFooter {
    sanityFooterv2 {
      bottomLinks {
        ...linkFragment
      }
    }
  }
`;

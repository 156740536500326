import React from 'react';
import { LangsSection } from '../langsSection/LangSection';
import * as styles from './bottomSection.module.scss';
import { LogoSite } from '../../logoSite/LogoSite';
import { BottomLinks } from '../bottomLinks/BottomLinks';

export const BottomSection = () => (
  <div className={styles.mainWrapper}>
    <LangsSection />
    <LogoSite dark />
    <div className={styles.legals}>
      <BottomLinks />
    </div>
    <LogoSite dark />
  </div>
);

import React from 'react';
import { Socials } from './socials/Socials';
import { ButtonsSection } from './buttonsSection/ButtonsSection';
import { NavSectionMobile } from './navSection/NavSectionMobile';
import * as styles from './footer.module.scss';
import { NavSection } from './navSection/NavSection';
import { PartnersSection } from './partnersSection/PartnersSection';
import { BottomSection } from './bottomSection/BottomSection';

export const Footerv2 = () => (
  <div className={styles.mainWrapper}>
    <div>
      <Socials />
      <ButtonsSection />
    </div>
    <div>
      <NavSectionMobile />
      <NavSection />
    </div>
    <div>
      <PartnersSection />
    </div>
    <div>
      <BottomSection />
    </div>
  </div>
);

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  faFacebook,
  faFacebookF,
  faYoutube,
  faXTwitter,
  faTwitch,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faSnapchat,
  faSnapchatGhost,
  faPinterest,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { LocaleLink } from '../../localeLink/LocaleLink';
import * as styles from './socials.module.scss';
import { SocialsFooterQuery } from '../../../../../graphql-types';

library.add(
  faFacebook,
  faFacebookF,
  faYoutube,
  faXTwitter,
  faTwitch,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faSnapchat,
  faSnapchatGhost,
  faPinterest,
  faPinterestP
);

export const Socials = () => {
  const { sanityFooterv2 } = useStaticQuery<SocialsFooterQuery>(QUERY);
  const socials = sanityFooterv2?.socialNetwork;
  return (
    <div className={styles.mainWrapper}>
      {socials?.map((social) => {
        if (social?.icon && social?.networkUrl) {
          return (
            <LocaleLink
              key={social?._key}
              to={social.networkUrl}
              target="_blank"
              ariaLabel={social.icon}
            >
              <FontAwesomeIcon icon={['fab', social.icon as IconName]} />
            </LocaleLink>
          );
        }
        return null;
      })}
    </div>
  );
};

const QUERY = graphql`
  query socialsFooter {
    sanityFooterv2 {
      socialNetwork {
        icon
        networkUrl
        _key
      }
    }
  }
`;

// extracted by mini-css-extract-plugin
export var beigeBg = "menuDesktop-module--beigeBg--b6e3c";
export var cardLinks = "menuDesktop-module--cardLinks--c3bc3";
export var closeButton = "menuDesktop-module--closeButton--2c8d1";
export var externalLinks = "menuDesktop-module--externalLinks--5fb32";
export var hidden = "menuDesktop-module--hidden--5d83c";
export var iconLink = "menuDesktop-module--iconLink--231a5";
export var iconLinks = "menuDesktop-module--iconLinks--28a48";
export var menu = "menuDesktop-module--menu--8bc71";
export var menuHidden = "menuDesktop-module--menuHidden--61eae";
export var rubriques = "menuDesktop-module--rubriques--b7b23";
export var selectionTitle = "menuDesktop-module--selectionTitle--10f25";
export var selections = "menuDesktop-module--selections--9eea1";
export var title = "menuDesktop-module--title--5bbf5";
export var whiteBg = "menuDesktop-module--whiteBg--b288e";
export var wrapper = "menuDesktop-module--wrapper--0d385";
import React from 'react';
import FavoriteSVG from '../../../../static/svg/favorite.svg';
import { useWishlistContext } from '../../../hooks/contexts/useWishlistContext';
import * as styles from './favoriteIcon.module.scss';

export const FavoriteIcon = () => {
  const { wishlist } = useWishlistContext();
  const nbWish = wishlist.length;
  return (
    <div className={styles.mainWrapper}>
      {nbWish ? <div className={styles.nbWish}>{nbWish}</div> : null}
      <FavoriteSVG />
    </div>
  );
};

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { MegaMenuQuery } from '../../../../../graphql-types';
import { SmartLink } from '../../../../components/2.0version/smartLink/SmartLink';
import { usePageContext } from '../../../../hooks/contexts/usePageContext';
import * as styles from './menuCard.module.scss';

interface IMenuCardProps {
  cardLink: NonNullable<NonNullable<MegaMenuQuery['sanityMenuv2']>['sejourCardLinks']>[0];
}

export const MenuCard = ({ cardLink }: IMenuCardProps) => {
  const { lang } = usePageContext();
  const image = getImage(cardLink?.image?.image?.asset?.gatsbyImageData);

  if (!image) return null;

  return (
    <SmartLink link={cardLink?.link}>
      <div className={styles.wrapper}>
        <GatsbyImage alt="tours-tourisme" image={image} className={styles.image} />
        <div className={styles.shadow} />
        <div className={styles.absolute}>
          <p>{cardLink?.link?._rawTitle?.[lang]}</p>
        </div>
      </div>
    </SmartLink>
  );
};

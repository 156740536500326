import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import React from 'react';
import { useMenuContext } from '../../hooks/contexts/useMenuContext';
import * as styles from './menu.module.scss';
import { MegaMenuMobileQuery } from '../../../graphql-types';
import { SmartLink } from '../../components/2.0version/smartLink/SmartLink';
import { usePageContext } from '../../hooks/contexts/usePageContext';
import { LocaleLink } from '../../components/2.0version/localeLink/LocaleLink';
import { MenuLinkContent } from './components/menuLinkContent/MenuLinkContent';
import { MenuCard } from './components/menuCard/MenuCard';
import { LangSwitch } from './components/langSwitch/LangSwitch';
import { URLS } from '../../../constants';

const TODOmenuTrad = 'Menu';

export const Menu = () => {
  const { lang } = usePageContext();
  const { open, setOpen } = useMenuContext();
  const { sanityMenuv2, sanitySejourPage } = useStaticQuery<MegaMenuMobileQuery>(QUERY);

  return (
    <div className={`${styles.wrapper} ${!open && styles.hidden}`}>
      <header>
        <p className={styles.menuTitle}>{TODOmenuTrad}</p>
        <button
          aria-label="close menu"
          type="button"
          className={styles.closeButton}
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </header>

      <div className={styles.overLinks}>
        <SmartLink link={sanityMenuv2?.desktopOverBarLinks?.[0]}>
          <span>{sanityMenuv2?.desktopOverBarLinks?.[0]?._rawTitle?.[lang]}</span>
        </SmartLink>
        <div className={styles.divider} />
        <SmartLink link={sanityMenuv2?.desktopOverBarLinks?.[1]}>
          <span>{sanityMenuv2?.desktopOverBarLinks?.[1]?._rawTitle?.[lang]}</span>
        </SmartLink>
      </div>

      <div className={styles.linkList}>
        <p className={styles.title}>{sanitySejourPage?.pageHeader?._rawTitle?.[lang]}</p>
        <ul className={styles.selections}>
          {sanitySejourPage?.select?.map((selec) => (
            <li key={`$selec-${selec?._key}`}>
              <p className={styles.selectionTitle}>{selec?._rawTitleMenu?.[lang]}</p>
              <ul>
                {selec?.rubriques?.map((rubr) => (
                  <li key={`$selec-rubr${rubr?.id}`}>
                    <LocaleLink to={`${URLS['preparez-votre-sejour'].url}/${rubr?.slug?.current}`}>
                      <MenuLinkContent title={rubr?._rawTitle?.[lang]} />
                    </LocaleLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <ul className={styles.iconLinks}>
          {sanityMenuv2?.sejourIconLinks?.map((el) => (
            <li key={`$icon-li-${el?._key}`}>
              <SmartLink link={el?.link}>
                <MenuLinkContent
                  prefix="far"
                  icon={el?.icon as IconName}
                  title={el?.link?._rawTitle?.[lang]}
                />
              </SmartLink>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.whiteBg}>
        <div className={styles.cardLinks}>
          <p className={styles.title}>{sanityMenuv2?._rawCardsTitle?.[lang]}</p>
          <ul>
            {sanityMenuv2?.sejourCardLinks?.map((el) => (
              <li key={`menu-card-l${el?._key}`}>
                <MenuCard cardLink={el} />
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.externalLinks}>
          <p className={styles.title}>{sanityMenuv2?._rawExterneTitle?.[lang]}</p>
          <ul className={styles.selections}>
            {sanityMenuv2?.externeLinks?.map((el) => (
              <li key={`$extern-${el?._key}`}>
                <SmartLink link={el}>
                  <MenuLinkContent title={el?._rawTitle?.[lang]} />
                </SmartLink>
              </li>
            ))}
          </ul>
        </div>

        <LangSwitch />
      </div>
    </div>
  );
};

const QUERY = graphql`
  query MegaMenuMobile {
    sanityMenuv2 {
      desktopOverBarLinks {
        ...linkFragment
      }
      _rawCardsTitle
      _rawExterneTitle
      _rawSejourTitle
      sejourCardLinks {
        _key
        image {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        link {
          ...linkFragment
        }
      }
      sejourIconLinks {
        _key
        icon
        link {
          ...linkFragment
        }
      }
      externeLinks {
        ...linkFragment
      }
    }
    sanitySejourPage {
      pageHeader {
        _rawTitle
      }
      select {
        _rawTitleMenu
        _key
        rubriques {
          id
          _rawTitle
          slug {
            current
          }
        }
      }
    }
  }
`;

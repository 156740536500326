/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackpack,
  faBed,
  faBedEmpty,
  faBuilding,
  faBus,
  faCalendar,
  faCamera,
  faCampground,
  faCar,
  faConciergeBell,
  faGiftCard,
  faGlassChampagne,
  faGlassCheers,
  faHeart,
  faHotel,
  faLeaf,
  faMapMarkedAlt,
  faMeat,
  faShoppingBag,
  faSunCloud,
  faTicket,
  faUtensils,
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { IconName } from '@fortawesome/free-brands-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMenuContext } from '../../hooks/contexts/useMenuContext';
import * as styles from './menuDesktop.module.scss';
import { MegaMenuQuery } from '../../../graphql-types';
import { SmartLink } from '../../components/2.0version/smartLink/SmartLink';
import { usePageContext } from '../../hooks/contexts/usePageContext';
import { LocaleLink } from '../../components/2.0version/localeLink/LocaleLink';
import { MenuCard } from './components/menuCard/MenuCard';
import { LangSwitch } from './components/langSwitch/LangSwitch';
import { URLS } from '../../../constants';

library.add(
  faBackpack,
  faBed,
  faBedEmpty,
  faBuilding,
  faBus,
  faCalendar,
  faCamera,
  faCampground,
  faCar,
  faConciergeBell,
  faGiftCard,
  faGlassChampagne,
  faGlassCheers,
  faHeart,
  faHotel,
  faLeaf,
  faMapMarkedAlt,
  faMeat,
  faShoppingBag,
  faSunCloud,
  faTicket,
  faUtensils
);

const makeColumns = (sanitySejourPage) => {
  const columns = sanitySejourPage?.select?.reduce((acc, current) => {
    const rubriquesRight = current?.rubriques?.slice(6);
    const rubriquesLeft = current?.rubriques?.slice(0, 6);

    acc?.push({ ...current, rubriques: rubriquesLeft });
    if (rubriquesRight?.length) acc.push({ rubriques: rubriquesRight });
    return acc;
  }, [] as any);
  return columns;
};

export const MenuDesktop = () => {
  const { lang } = usePageContext();
  const { open, setOpen } = useMenuContext();

  const { sanityMenuv2, sanitySejourPage } = useStaticQuery<MegaMenuQuery>(QUERY);

  const columns = useMemo(() => makeColumns(sanitySejourPage), [sanitySejourPage]);

  return (
    <div className={`${styles.wrapper} ${!open && styles.hidden}`} onClick={() => setOpen(false)}>
      <div
        className={`${styles.menu} ${!open && styles.menuHidden}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          aria-label="close menu"
          type="button"
          className={styles.closeButton}
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className={styles.beigeBg}>
          <p className={styles.title}>{sanitySejourPage?.pageHeader?._rawTitle?.[lang]}</p>
          <ul className={styles.selections}>
            {columns.map((column) => (
              <li key={`$selec-${column?._key}`}>
                <p className={styles.selectionTitle}>{column?._rawTitleMenu?.[lang]}</p>
                <div>
                  <ul className={styles.rubriques}>
                    {column?.rubriques?.map((rubr) => (
                      <li key={`$selec-rubr-${rubr?.id}`}>
                        <LocaleLink
                          to={`${URLS['preparez-votre-sejour'].url}/${rubr?.slug?.current}`}
                        >
                          <p>{rubr?._rawTitle?.[lang]}</p>
                        </LocaleLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
            {sanityMenuv2?._rawExtraTitle?.[lang] && (
              <li key="extra-links">
                <ul>
                  <p className={styles.selectionTitle}>{sanityMenuv2?._rawExtraTitle?.[lang]}</p>
                  {sanityMenuv2?.extraLinks
                    ?.filter((link) => link?._rawTitle?.[lang])
                    .map((link) => (
                      <li key={link?._key}>
                        <SmartLink link={link}>
                          <p>{link?._rawTitle?.[lang]}</p>
                        </SmartLink>
                      </li>
                    ))}
                </ul>
              </li>
            )}

            {(sanityMenuv2?.sejourIconLinks?.length ?? 0) > 0 && (
              <li key={`$selec-${sanitySejourPage?.select?.length ?? 0}`}>
                <ul className={styles.iconLinks}>
                  {sanityMenuv2?.sejourIconLinks?.map((el) => (
                    <li key={`$icon-li-${el?._key}`}>
                      <SmartLink link={el?.link}>
                        <div className={styles.iconLink}>
                          {el?.icon && <FontAwesomeIcon icon={['far', el.icon as IconName]} />}
                          <p>{el?.link?._rawTitle?.[lang]}</p>
                        </div>
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
          {/* <ul className={styles.iconLinks}>
          {sanityMenuv2?.sejourIconLinks?.map((el, index) => (
            <li key={`$icon-li-${index}`}>
              <SmartLink link={el?.link}>
                <MenuLinkContent icon={faShoePrints} title={el?.link?._rawTitle?.[lang]} />
              </SmartLink>
            </li>
          ))}
        </ul> */}
        </div>

        <div className={styles.whiteBg}>
          {(sanityMenuv2?.sejourCardLinks?.length ?? 0) > 0 && (
            <>
              <p className={styles.title}>{sanityMenuv2?._rawCardsTitle?.[lang]}</p>
              <ul className={styles.cardLinks}>
                {sanityMenuv2?.sejourCardLinks?.map((el) => (
                  <li key={`menu-card-l${el?._key}`}>
                    <MenuCard cardLink={el} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {(sanityMenuv2?.externeLinks?.length ?? 0) > 0 && (
            <>
              <p className={styles.title}>{sanityMenuv2?._rawExterneTitle?.[lang]}</p>
              <ul className={styles.externalLinks}>
                {sanityMenuv2?.externeLinks?.map((el) => (
                  <li key={`$extern-${el?._key}`}>
                    <SmartLink link={el}>
                      <p>{el?._rawTitle?.[lang]} </p>
                    </SmartLink>
                  </li>
                ))}
              </ul>
            </>
          )}

          <LangSwitch />
        </div>
      </div>
    </div>
  );
};

const QUERY = graphql`
  query MegaMenu {
    sanityMenuv2 {
      desktopOverBarLinks {
        ...linkFragment
      }
      _rawCardsTitle
      _rawExterneTitle
      _rawSejourTitle
      _rawExtraTitle
      sejourCardLinks {
        _key
        image {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        link {
          ...linkFragment
        }
      }
      sejourIconLinks {
        _key
        icon
        link {
          ...linkFragment
        }
      }
      externeLinks {
        ...linkFragment
      }
      extraLinks {
        ...linkFragment
      }
    }
    sanitySejourPage {
      pageHeader {
        _rawTitle
      }
      select {
        _rawTitleMenu
        _key
        rubriques {
          id
          _rawTitle
          slug {
            current
          }
        }
      }
    }
  }
`;

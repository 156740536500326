import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import * as styles from './header.module.scss';
import { LogoSite } from '../../components/2.0version/logoSite/LogoSite';
import SearchIcon from '../../../static/svg/search.svg';
import AccountIcon from '../../../static/svg/person.svg';
import { LocaleLink } from '../../components/2.0version/localeLink/LocaleLink';
import { useMenuContext } from '../../hooks/contexts/useMenuContext';
import { INGENIE_ACCOUNT_URL, URLS } from '../../../constants';
import { usePageContext } from '../../hooks/contexts/usePageContext';
import { WidgetCart } from '../../modules/ingenie/WidgetCart';
import { FavoriteIcon } from '../../components/2.0version/favoriteIcon/FavoriteIcon';

export const Header = () => {
  const { open, setOpen } = useMenuContext();
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.burgerWrapper}>
        <button
          aria-label="menu"
          type="button"
          className={styles.menuIcon}
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon={faBars} size="1x" />
        </button>
      </div>
      <LogoSite dark />
      <div className={styles.icons}>
        <LocaleLink to="/recherche" ariaLabel="search">
          <SearchIcon />
        </LocaleLink>
        <LocaleLink to={INGENIE_ACCOUNT_URL[lang]} ariaLabel="account">
          <AccountIcon />
        </LocaleLink>
        <LocaleLink to={URLS.favorites.url} ariaLabel="favorites">
          <FavoriteIcon />
        </LocaleLink>
        <WidgetCart />
      </div>
    </div>
  );
};

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { LogoSiteQuery } from '../../../../graphql-types';
import { LocaleLink } from '../localeLink/LocaleLink';
import * as styles from './logoSite.module.scss';

interface ILogoSite {
  dark?: boolean;
  withoutText?: boolean;
}

export const LogoSite = ({ dark = false, withoutText = false }: ILogoSite) => {
  const { sanitySettingsLayout } = useStaticQuery<LogoSiteQuery>(QUERY);

  // eslint-disable-next-line no-nested-ternary
  const image = withoutText
    ? getImage(sanitySettingsLayout?.logoWithoutText?.asset?.gatsbyImageData)
    : dark
    ? getImage(sanitySettingsLayout?.logoDark?.asset?.gatsbyImageData)
    : getImage(sanitySettingsLayout?.logoLight?.asset?.gatsbyImageData);

  return (
    <LocaleLink
      to="/"
      className={`${withoutText ? styles.withoutText : styles.wrapper}`}
      data-withouttext={withoutText}
    >
      {image && <GatsbyImage image={image} alt="tours" loading="eager" />}
    </LocaleLink>
  );
};

const QUERY = graphql`
  query LogoSite {
    sanitySettingsLayout {
      logoDark {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
      logoLight {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
      logoWithoutText {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  }
`;

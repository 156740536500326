import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import {
  SanityLocaleString,
  SanityLocaleText,
  DefaultLangQuery,
  SanityLocaleFile,
} from '../../../graphql-types';
import useLocalStorage from '../useLocalStorage';

type TPageContext = {
  originalPath: string;
  lang: keyof SanityLocaleFile & keyof SanityLocaleString & keyof SanityLocaleText & string;
  setLang: (lang: string) => any;
  cookieBanner: boolean;
  setCookieBanner: (open: boolean) => any;
  defaultLang: string;
  paths: { label: SanityLocaleString; url: string }[];
  menuClear?: boolean;
  menuRed?: boolean;
  searchBarDisable?: boolean;
  showPopupNewsletter: boolean;
  setShowPopupNewsletter: (value: boolean) => void;
};

export type Path = {
  label: SanityLocaleString;
  url: string;
};

const PageContext = React.createContext<TPageContext | undefined>(undefined);

export const PageContextProvider: React.FC<{
  pageContext: any;
  children: React.ReactNode;
}> = ({ pageContext, children }) => {
  const { site } = useStaticQuery<DefaultLangQuery>(QUERY);
  const [lang, setLang] = useState<string>(pageContext.lang);
  const [cookieBanner, setCookieBanner] = useState<boolean>(false);
  const [showPopupNewsletter, setShowPopupNewsletter] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useLocalStorage<boolean>('newsletter', false);

  const defaultLang = site?.siteMetadata?.defaultLanguage;

  // useEffect(() => {
  //   if (navigator && site?.siteMetadata && site?.siteMetadata?.extraLanguages?.length) {
  //     const langs = [site.siteMetadata.defaultLanguage, ...site.siteMetadata.extraLanguages];
  //     const browserLanguageMatch = langs.find((language) => {
  //       const regex = new RegExp(`^${language}\\b`);
  //       return regex.test(navigator.language);
  //     });
  //     if (browserLanguageMatch) setLang(browserLanguageMatch);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!newsletter) {
      setTimeout(() => {
        setNewsletter(true);
        setShowPopupNewsletter(true);
      }, 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNewsletter, setShowPopupNewsletter]);

  useEffect(() => {
    navigate(
      `${lang !== defaultLang ? `/${lang}${pageContext.originalPath}` : pageContext.originalPath}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const value = useMemo(
    () => ({
      ...pageContext,
      lang,
      setLang,
      cookieBanner,
      setCookieBanner,
      showPopupNewsletter,
      setShowPopupNewsletter,
      defaultLang,
    }),
    [
      lang,
      cookieBanner,
      pageContext,
      setCookieBanner,
      setLang,
      showPopupNewsletter,
      setShowPopupNewsletter,
      defaultLang,
    ]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);

  if (!pageContext) throw new Error('No PageContext.Provider found when calling usePageContext.');
  return pageContext;
};

const QUERY = graphql`
  query DefaultLang {
    site {
      siteMetadata {
        defaultLanguage
        extraLanguages
      }
    }
  }
`;

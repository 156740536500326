import React from 'react';
import { usePageContext } from '../../../hooks/contexts/usePageContext';
import * as styles from './langSelector.module.scss';

export const langs = [
  { code: 'fr', label: { en: 'French', fr: 'Francais' } },
  { code: 'en', label: { en: 'English', fr: 'Anglais' } },
];

export const LangSelector = () => {
  const { lang, setLang } = usePageContext();

  const activeLang = langs.find((el) => el.code === lang);
  const extraLangs = langs.filter((el) => el.code !== lang);

  return (
    <div>
      <select name="lang" onChange={(e) => setLang(e.target.value)} className={styles.wrapper}>
        <option value={activeLang?.code}>{activeLang?.label[lang]}</option>
        {extraLangs.map((el) => (
          <option key={`lang-select-${el.code}`} value={el.code}>
            {el.label[lang]}
          </option>
        ))}
      </select>
    </div>
  );
};

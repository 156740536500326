import React, { HTMLAttributeAnchorTarget } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LocaleLink } from '../localeLink/LocaleLink';
import { URLS } from '../../../../constants';
import { SmartLinkType } from '../../../graphql/inferedTypes';
import { AllProParentPagesQuery, SanityPushes } from '../../../../graphql-types';
import { usePageContext } from '../../../hooks/contexts/usePageContext';

interface ISmartLink {
  link?: SmartLinkType | SanityPushes['link'];
  children: React.ReactNode;
  className?: string;
}

export const SmartLink = ({ link, children, className }: ISmartLink) => {
  const { lang } = usePageContext();
  const { allSanityPagePro } = useStaticQuery<AllProParentPagesQuery>(QUERY);
  if (!link) return null;

  const external = link?.destinationType === 'external';
  const dynamic = link?.pageType === 'dynamic';
  const file = link?.destinationType === 'file';

  if (!external && !dynamic && !URLS[link.staticPage as string]?.url && !file) {
    throw new Error('Missing page slugs in urls object SMARTLINK');
  }

  let url: string | null | undefined = null;
  if (external) url = link?.href;
  else if (dynamic) url = `/${link?.dynamicPage?.slug?.current}`;
  else if (file) url = link?.document?.[lang]?.asset?.url;
  else url = URLS[link?.staticPage as string]?.url;

  if (dynamic) {
    switch (link?.dynamicPage?.__typename) {
      case 'SanityPagePro':
        if (link?.dynamicPage?.type === 'child') {
          const parentSlug = allSanityPagePro?.nodes?.find(
            (page) => page?.childPages?.findIndex((el) => el?.id === link?.dynamicPage?.id) !== -1
          )?.slug?.current;

          if (!parentSlug) return null;
          url = `/${parentSlug}${url}`;
        }
        break;
      case 'SanityIncontournablesItem':
        url = `${URLS.incontournables.url}${url}`;
        break;
      case 'SanityListingSelection':
        url = `${URLS['preparez-votre-sejour'].url}${url}`;
        break;
      case 'SanityBlogCategory':
        url = `${URLS.blog.url}${url}`;
        break;
      case 'SanityBlogArticle':
        url = `${URLS.blog.url}/${link.dynamicPage.category?.[0]?.slug?.current}${url}`;
        break;
      default:
        break;
    }
  }

  return (
    <LocaleLink to={url} target={link?.target as HTMLAttributeAnchorTarget} className={className}>
      {children}
    </LocaleLink>
  );
};

const QUERY = graphql`
  query AllProParentPages {
    allSanityPagePro(filter: { type: { eq: "parent" } }) {
      nodes {
        slug {
          current
        }
        childPages {
          id
        }
      }
    }
  }
`;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { LangSelector } from '../../components/2.0version/langSelector/LangSelector';
import * as styles from './desktopTopBar.module.scss';
import { TopBarQuery } from '../../../graphql-types';
import { SmartLink } from '../../components/2.0version/smartLink/SmartLink';
import { usePageContext } from '../../hooks/contexts/usePageContext';

export const DesktopTopBar = () => {
  const { sanityMenuv2 } = useStaticQuery<TopBarQuery>(QUERY);
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      {sanityMenuv2?.desktopOverBarLinks?.map((el) => (
        <SmartLink link={el} key={`top-bar-${el?._key}`}>
          <p>{el?._rawTitle?.[lang]}</p>
        </SmartLink>
      ))}
      <LangSelector />
    </div>
  );
};

const QUERY = graphql`
  query TopBar {
    sanityMenuv2 {
      desktopOverBarLinks {
        ...linkFragment
      }
    }
  }
`;

import React from 'react';
import * as styles from './button.module.scss';

interface IButtonProps {
  name?: string;
  type?: 'submit' | 'button';
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  white?: boolean;
  wide?: boolean;
}

export const Button = ({
  name = '', // mandatory for icon buttons
  type = 'button',
  onClick,
  children,
  white = false,
  wide = false,
}: IButtonProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {onClick ? (
      <button
        aria-label={name}
        className={`${styles.outlinedButton} ${white ? styles.white : ''} ${
          wide ? styles.wide : ''
        }`}
        type={type === 'button' ? 'button' : 'submit'}
        onClick={(e) => onClick(e)}
      >
        {children}
      </button>
    ) : (
      <div
        className={`${styles.outlinedButton} ${white ? styles.white : ''} ${
          wide ? styles.wide : ''
        }`}
      >
        {children}
      </div>
    )}
  </>
);

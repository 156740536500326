// extracted by mini-css-extract-plugin
export var cardLinks = "menu-module--cardLinks--87506";
export var closeButton = "menu-module--closeButton--5b284";
export var divider = "menu-module--divider--74ebe";
export var externalLinks = "menu-module--externalLinks--0312b";
export var hidden = "menu-module--hidden--c3c57";
export var iconLinks = "menu-module--iconLinks--105fc";
export var linkList = "menu-module--linkList--a5523";
export var menuTitle = "menu-module--menuTitle--4450d";
export var overLinks = "menu-module--overLinks--62897";
export var selectionTitle = "menu-module--selectionTitle--94f37";
export var selections = "menu-module--selections--4d519";
export var title = "menu-module--title--695e6";
export var whiteBg = "menu-module--whiteBg--5bc4a";
export var wrapper = "menu-module--wrapper--9ea7a";
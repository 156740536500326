import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as styles from './ScrollButton.module.scss';

export interface ScrollButtonProps {
  visible?: boolean;
}

export const ScrollButton: React.FC<ScrollButtonProps> = ({ visible = true }) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      aria-label="scroll up"
      type="button"
      onClick={handleClick}
      className={`${styles.wrapper} ${!visible ? styles.hidden : ''}`}
    >
      <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
    </button>
  );
};

import { Link } from 'gatsby';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { usePageContext } from '../../../hooks/contexts/usePageContext';

interface ILocaleLinkProps {
  to?: string | null;
  target?: HTMLAttributeAnchorTarget;
  children: React.ReactNode;
  className?: string;
  external?: boolean;
  ariaLabel?: string;
}

export const LocaleLink = ({
  to = '/',
  target = '_self',
  children,
  className,
  external = false,
  ariaLabel = '',
}: ILocaleLinkProps) => {
  const { lang, defaultLang } = usePageContext();
  const isExternal = external ? true : to === '..' || to?.indexOf('/') === 0 ? false : true;
  const localeTo = `${lang !== defaultLang && to !== '..' ? `/${lang}${to}` : to}`;
  return (
    <>
      {isExternal ? (
        <a href={to ?? '/'} target={target} className={className} aria-label={ariaLabel}>
          {children}
        </a>
      ) : (
        <Link to={localeTo} className={className} aria-label={ariaLabel}>
          {children}
        </Link>
      )}
    </>
  );
};

export const URLS = {
  home: { url: '/' },
  agenda: { url: '/agenda' },
  blog: { url: '/blog' },
  contact: { url: '/contact' },
  favorites: { url: '/favoris' },
  incontournables: { url: '/incontournables' },
  'preparez-votre-sejour': { url: '/preparez-votre-sejour' },
  legals: { url: '/mentions-legales' },
  dmc: { url: '/dmc' },
  meteo: { url: '/meteo' },
  newsletter: { url: '/newsletter' },
  'newsletter-pro': { url: '/newsletter-pro' },
  qualification: { url: '/qualification' },
  recherche: { url: '/recherche' },
  brochures: { url: '/brochures' },
  'tous-nos-articles': { url: '/tous-nos-articles' },
};

export const INGENIE_ACCOUNT_URL = {
  fr: 'https://reservation.tours-tourisme.fr/identification.html',
  en: 'https://booking.tours-tourisme.fr/identification.html',
};
